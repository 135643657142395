import { HttpClient } from '@angular/common/http';
import { ValidDomaine } from 'src/app/interfaces/config.interface';

export interface SSOPartnerConfig {
  CLIENT_ID: string;
  CLIENT_SECRET: string;
}

export interface SSOPartnerDetails {
  authorization_code_url: string;
  authorization_token_url: string;
}

export abstract class SSOPartnersService {
  protected constructor(
    protected http: HttpClient,
    protected apiUrl: string,
    protected partnerConfig: SSOPartnerConfig
  ) {}

  abstract domaine: ValidDomaine;

  /**
   * Gets the authorization URL for the SSO partner
   * @param currentUrl The current application URL to redirect back to
   * @param domaine The partner domain identifier
   */
  abstract getAuthorizationUrl(currentUrl: URL): Promise<URL>;

  /**
   * Gets an access token from the SSO partner
   * @param code The authorization code received from the partner
   * @param currentUrl The current application URL
   */
  abstract getAccessToken(code: string): Promise<string>;

  /**
   * Gets the current application URL
   */
  abstract getCurrentUrl(): URL;

  /**
   * Fetches the partner configuration details from the API
   * @param domaine The partner domain identifier
   */
  protected async getPartnerDetails(): Promise<SSOPartnerDetails | undefined> {
    return await this.http.get<SSOPartnerDetails>(`${this.apiUrl}/v3.0/partners/${this.domaine}`).toPromise();
  }

  /**
   * Converts an external token to an internal access token
   * @param externalToken The token received from the SSO partner
   * @param clientId The client ID for the internal API
   */
  async convertToken(externalToken: string, clientId: string): Promise<string> {
    const response = await this.http
      .post<{ access_token: string }>(
        `${this.apiUrl}/v3.0/oauth2/convert-token`,
        new URLSearchParams({
          grant_type: 'convert_token',
          token: externalToken,
          backend: this.domaine,
          client_id: clientId
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      .toPromise();

    return response?.access_token || '';
  }
}
