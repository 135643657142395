export async function randomString(length: number) {
  // Create a Uint8Array of the desired length
  const randomBuffer = new Uint8Array(length);

  // Fill it with cryptographically secure random values
  crypto.getRandomValues(randomBuffer);

  // Convert the random bytes to a string of hexadecimal characters
  let randomString = '';
  for (let i = 0; i < randomBuffer.length; i++) {
    // Convert each byte to a 2-character hex string
    const hex = randomBuffer[i].toString(16).padStart(2, '0');
    randomString += hex;
  }

  // Since each byte becomes 2 hex characters, we'll have twice the length
  // So trim it to the requested length
  return randomString.slice(0, length);
}
