import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigService } from '../config/config.service';
import { ValidDomaine } from 'src/app/interfaces/config.interface';
import { UserService } from '../user/user.service';
import { MancheSSOService } from './sso/manche/manche-sso.service';
import { SchneiderSSOService } from './sso/schneider/schneider-sso.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private API_CONFIG: any;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private userService: UserService,
    private mancheSSOService: MancheSSOService,
    private schneiderSSOService: SchneiderSSOService
  ) {
    this.API_CONFIG = this.configService.get('API_CONFIG');
  }

  async loginLocal(username: string, password: string) {
    this.userService.logout();

    const formData = new FormData();
    formData.append('client_id', this.API_CONFIG.CLIENT_ID);
    formData.append('grant_type', 'password');
    formData.append('username', username);
    formData.append('password', password);

    const response = await this.http.post(`${this.API_CONFIG.API_URL}/v3.0/oauth2/token/`, formData, {}).toPromise();

    if (response && response['access_token']) {
      localStorage.setItem('access_token', response['access_token']);
      localStorage.setItem('expires_at', (new Date().getTime() + response['expires_in'] * 1000).toString());
    } else {
      localStorage.removeItem('access_token');
    }
  }

  async redirectSSO(domaine: ValidDomaine) {
    this.userService.logout();

    const ssoService = this.getSSOService(domaine);
    const authorizationUrl = await ssoService.getAuthorizationUrl();

    window.open(authorizationUrl.toString(), '_self');
  }

  async loginSSO(domaine: ValidDomaine, code: string) {
    this.userService.logout();

    const ssoService = this.getSSOService(domaine);

    const externalToken = await ssoService.getAccessToken(code);
    const internalToken = await ssoService.convertToken(externalToken, this.API_CONFIG.CLIENT_ID);

    localStorage.setItem('access_token', internalToken);
  }

  async sudo(user_id: number) {
    const response = await this.http.post(`${this.API_CONFIG.API_URL}/v3.0/users/${user_id}/sudo`, {}).toPromise();

    if (response && response['access_token']) {
      localStorage.setItem('access_token', response['access_token']);
      location.reload();
    }
  }

  private getSSOService(domaine: ValidDomaine) {
    switch (domaine) {
      case 'manche':
        return this.mancheSSOService;
      case 'schneider':
        return this.schneiderSSOService;
      default:
        throw new Error(`Unsupported SSO domain: ${domaine}`);
    }
  }
}
