import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../config/config.service';
import { SSOPartnersService, SSOPartnerConfig } from '../sso-partners.service';
import { ValidDomaine } from 'src/app/interfaces/config.interface';

@Injectable({ providedIn: 'root' })
export class SchneiderSSOService extends SSOPartnersService {
  domaine: ValidDomaine = 'schneider';

  constructor(http: HttpClient, configService: ConfigService) {
    const apiConfig = configService.get('API_CONFIG');
    const schneiderConfig = configService.get('SCHNEIDER') as SSOPartnerConfig;
    super(http, apiConfig.API_URL, schneiderConfig);
  }

  async getAuthorizationUrl(): Promise<URL> {
    const currentUrl = this.getCurrentUrl();

    const partnerDetails = await this.getPartnerDetails();
    if (!partnerDetails) {
      throw new Error('No partner details found');
    }

    const authorizationUrl = new URL(partnerDetails.authorization_code_url);

    authorizationUrl.searchParams.set('redirect_uri', currentUrl.toString());

    return authorizationUrl;
  }

  async getAccessToken(code: string): Promise<string> {
    const partnerDetails = await this.getPartnerDetails();
    if (!partnerDetails) {
      throw new Error('No partner details found');
    }

    const currentUrl = this.getCurrentUrl();

    const params = new URLSearchParams({
      grant_type: 'authorization_code',
      code: code,
      client_id: this.partnerConfig.CLIENT_ID,
      redirect_uri: currentUrl.toString()
    });

    const response = await this.http
      .post<{ access_token: string }>(partnerDetails.authorization_token_url, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${btoa(`${this.partnerConfig.CLIENT_ID}:${this.partnerConfig.CLIENT_SECRET}`)}`
        }
      })
      .toPromise();

    return response?.access_token || '';
  }

  getCurrentUrl(): URL {
    const currentUrl = new URL(location.href);
    currentUrl.search = '';
    currentUrl.searchParams.append('state', JSON.stringify({ domaine: this.domaine }));

    currentUrl.searchParams.delete('code');
    currentUrl.hash = '';

    return currentUrl;
  }
}
