import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../config/config.service';
import { SSOPartnersService, SSOPartnerConfig } from '../sso-partners.service';
import { ValidDomaine } from 'src/app/interfaces/config.interface';
import { randomString } from '~shared/utils/string';

@Injectable({ providedIn: 'root' })
export class MancheSSOService extends SSOPartnersService {
  domaine: ValidDomaine = 'manche';

  constructor(http: HttpClient, configService: ConfigService) {
    const apiConfig = configService.get('API_CONFIG');
    const mancheConfig = configService.get('MANCHE') as SSOPartnerConfig;
    super(http, apiConfig.API_URL, mancheConfig);
  }

  async getAuthorizationUrl(): Promise<URL> {
    const currentUrl = this.getCurrentUrl();

    const partnerDetails = await this.getPartnerDetails();
    if (!partnerDetails) {
      throw new Error('No partner details found');
    }

    const authorizationUrl = new URL(partnerDetails.authorization_code_url);
    const codeVerifier = await randomString(43);

    localStorage.setItem('pkce_code_verifier', codeVerifier);

    authorizationUrl.searchParams.set('client_id', this.partnerConfig.CLIENT_ID);
    authorizationUrl.searchParams.set('code_challenge', codeVerifier);
    authorizationUrl.searchParams.set('code_challenge_method', 'plain');

    authorizationUrl.searchParams.set('redirect_uri', currentUrl.toString());
    authorizationUrl.searchParams.set('state', JSON.stringify({ domaine: this.domaine }));

    return authorizationUrl;
  }

  async getAccessToken(code: string): Promise<string> {
    const partnerDetails = await this.getPartnerDetails();
    if (!partnerDetails) {
      throw new Error('No partner details found');
    }

    const currentUrl = this.getCurrentUrl();
    const codeVerifier = localStorage.getItem('pkce_code_verifier');
    if (!codeVerifier) {
      throw new Error('No code verifier found');
    }

    const params = new URLSearchParams({
      code: code,
      code_verifier: codeVerifier,
      grant_type: 'authorization_code',
      redirect_uri: currentUrl.toString(),
      client_id: this.partnerConfig.CLIENT_ID
    });

    const response = await this.http
      .post<{ access_token: string }>(partnerDetails.authorization_token_url, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .toPromise();

    return response?.access_token || '';
  }

  getCurrentUrl(): URL {
    const currentUrl = new URL(location.href);

    currentUrl.search = '';
    currentUrl.pathname = '';

    currentUrl.searchParams.delete('code');
    currentUrl.hash = '';
    return currentUrl;
  }
}
